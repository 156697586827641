<template>
  <b-modal
    id="modalTask"
    v-model="ownControl"
    modal
    scrollable
    title-class="h3 text-white font-weight-bolder"
    header-class="class_modal_js"
    size="lg"
    @hidden="closeModal"
  >
    <template #modal-header>
      <div class="d-flex justify-content-between w-100">
        <div class="text-center">
          <span style="font-size: 20px; font-weight: 900; color: #ffffff"
            >WEEKLY ACTIVITIES</span
          >
        </div>
        <feather-icon
          icon="XIcon"
          class="cursor-pointer"
          size="25"
          @click="closeModal"
        />
      </div>
    </template>

    <validation-observer ref="form">

      <!-- CATCHER -->
      <b-form-group>
        <validation-provider
          v-slot="{ errors, valid }"
          name="catcher"
          rules="required"
        >
          <b-input-group>
            <b-input-group-prepend>
              <b-input-group-text
                class="bg-primary text-white"
                style="padding-right: 5.25em"
              >
                <span>CATCHER:</span>
              </b-input-group-text>
            </b-input-group-prepend>
            <div class="d-flex align-items-center justify-content-around w-100">
              <b-form-select
                v-model="catcherId"
                :options="catchers"
                text-field="user_name"
                value-field="id"
                :disabled="disabled"
                :state="errors[0] ? false : valid ? true : null"
              />
            </div>
          </b-input-group>
          <span v-if="errors[0]" class="text-danger">
            CATCHER {{ errors[0] }}
          </span>
        </validation-provider>
      </b-form-group>

      <!-- FANPAGE -->
      <b-form-group>
        <validation-provider
          v-slot="{ errors, valid }"
          name="fanpage"
          rules="required"
        >
          <b-input-group>
            <b-input-group-prepend>
              <b-input-group-text
                class="bg-primary text-white"
                style="padding-right: 5.25em"
              >
                <span>FANPAGE:</span>
              </b-input-group-text>
            </b-input-group-prepend>
            <div class="d-flex align-items-center justify-content-around w-100">
              <b-form-select
                v-model="fanpageId"
                :options="fanpages"
                text-field="value"
                value-field="id"
                :disabled="disabled"
                :state="errors[0] ? false : valid ? true : null"
              />
            </div>
          </b-input-group>
          <span v-if="errors[0]" class="text-danger">
            FANPAGE {{ errors[0] }}
          </span>
        </validation-provider>
      </b-form-group>

      <!-- DATE -->
      <b-row v-if="editTask === false">
        <b-col sm="6">
          <b-form-group>
            <validation-provider
              v-slot="{ errors }"
              name="front"
              rules="required"
            >
              <b-input-group>
                <b-input-group-prepend>
                  <b-input-group-text
                    class="bg-primary text-white"
                    style="padding-right: 5.25em"
                  >
                    <span>FRONT:</span>
                  </b-input-group-text>
                </b-input-group-prepend>
                <div
                  class="d-flex align-items-center justify-content-around w-100"
                >
                <kendo-datepicker
                    v-model="front"
                    v-mask="'##/##/####'"
                    class="kk-picker-wrap form-control"
                    :class="[
                      'w-100 bg-transparent k-picker-custom',
                      { 'text-white': errors },
                    ]"
                    :format="'MM/dd/yyyy'"
                    readonly
                  />
                </div>
              </b-input-group>
              <span v-if="errors[0]" class="text-danger">
                FRONT {{ errors[0] }}
              </span>
            </validation-provider>
          </b-form-group>
        </b-col>

        <b-col sm="6">
          <b-form-group>
            <validation-provider v-slot="{ errors }" name="to" rules="required">
              <b-input-group>
                <b-input-group-prepend>
                  <b-input-group-text
                    class="bg-primary text-white"
                    style="padding-right: 5.25em"
                  >
                    <span>TO:</span>
                  </b-input-group-text>
                </b-input-group-prepend>
                <div
                  class="d-flex align-items-center justify-content-around w-100"
                >
                  <kendo-datepicker
                    v-model="to"
                    v-mask="'##/##/####'"
                    class="kk-picker-wrap form-control"
                    :class="[
                      'w-100 bg-transparent k-picker-custom',
                      { 'text-white': errors },
                    ]"
                    :format="'MM/dd/yyyy'"
                    readonly
                  />
                </div>
              </b-input-group>
              <span v-if="errors[0]" class="text-danger">
                TO {{ errors[0] }}
              </span>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row v-else class="py-1">
        <b-col sm="3"></b-col>
        <b-col sm="6">
          <b-form-group>
            <validation-provider
              v-slot="{ errors }"
              name="front"
              rules="required"
            >
              <b-input-group>
                <b-input-group-prepend>
                  <b-input-group-text
                    class="bg-primary text-white"
                    style="padding-right: 5.25em"
                  >
                    <span>DATE:</span>
                  </b-input-group-text>
                </b-input-group-prepend>
                <div
                  class="d-flex align-items-center justify-content-around w-60"
                >
                <kendo-datepicker
                    v-model="front"
                    v-mask="'##/##/####'"
                    class="kk-picker-wrap form-control"
                    :class="[
                      'w-100 bg-transparent k-picker-custom',
                      { 'text-white': errors },
                    ]"
                    :format="'MM/dd/yyyy'"
                    readonly
                  />
                </div>
              </b-input-group>
              <span v-if="errors[0]" class="text-danger">
                FRONT {{ errors[0] }}
              </span>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col sm="3"></b-col>
      </b-row>

      <!-- TIME -->
      <b-row>
        <b-col sm="6">
          <b-form-group>
            <validation-provider
              v-slot="{ errors }"
              name="front_time"
              rules="required"
            >
              <b-input-group>
                <b-input-group-prepend>
                  <b-input-group-text
                    class="bg-primary text-white"
                    style="padding-right: 5.25em"
                  >
                    <span>FRONT TIME:</span>
                  </b-input-group-text>
                </b-input-group-prepend>
                <div
                  class="d-flex align-items-center justify-content-around w-100"
                >
                  <kendo-timepicker
                    v-model="front_time"
                    :format="'HH:mm'"
                    minutes-step="30"
                    class="w-100 rounded bg-transparent k-picker-custom"
                    style="height: 2.73rem"
                    :class="{ 'border-danger': errors[0] ? true : false }"
                    placeholder="Select hour"
                  />
                </div>
              </b-input-group>
              <span v-if="errors[0]" class="text-danger">
                FRONT TIME {{ errors[0] }}
              </span>
            </validation-provider>
          </b-form-group>
        </b-col>

        <b-col sm="6">
          <b-form-group>
            <validation-provider
              v-slot="{ errors }"
              name="to_time"
              rules="required"
            >
              <b-input-group>
                <b-input-group-prepend>
                  <b-input-group-text
                    class="bg-primary text-white"
                    style="padding-right: 5.25em"
                  >
                    <span>TO TIME:</span>
                  </b-input-group-text>
                </b-input-group-prepend>
                <div
                  class="d-flex align-items-center justify-content-around w-100"
                >
                  <kendo-timepicker
                    v-model="to_time"
                    :format="'HH:mm'"
                    minutes-step="30"
                    class="w-100 rounded bg-transparent k-picker-custom"
                    style="height: 2.73rem"
                    :class="{ 'border-danger': errors[0] ? true : false }"
                    placeholder="Select hour"
                  />
                </div>
              </b-input-group>
              <span v-if="errors[0]" class="text-danger">
                TO TIME {{ errors[0] }}
              </span>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>
    </validation-observer>

    <template #modal-footer>
      <div>
        <b-button variant="primary" @click="assignFanpage()" v-if="!roleDisabled">
          <b-spinner v-if="spinnerOn" small />
          <feather-icon icon="SaveIcon" size="15" v-else />
           {{editTask == true ? "UPDATE" : "SAVE"}}
        </b-button>
      </div>
    </template>
  </b-modal>
</template>
  <script>
import { mapGetters } from "vuex";

import AnswersGuideService from "@/views/social-network/views/answers-guide/answers-guide.service";

import ActivitiesService from "@/views/social-network/views/activities/activities.service";

import GlobalService from "../../../../../../service/global";

export default {
  props: ["editTask","taskFanPage"],
  data() {
    return {
      activitieId: null,
      ownControl: false,
      spinnerOn: false,
      catcherId: null,
      catchers: [],
      front_time: "",
      to_time: "",
      front: "",
      to: "",
      fanpages: [],
      fanpageId: null,
      disabled: false,
      roleDisabled: false,
    };
  },

  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
      token: "auth/token",
    }),
  },

  created() {
    this.ownControl = true;
    if(this.editTask === true){
      this.activitieId = this.taskFanPage.id;
      this.catcherId = this.taskFanPage.catcherId;
      this.fanpageId = this.taskFanPage.fanpageId;
      this.front = this.taskFanPage.front;
      this.to = this.taskFanPage.to;
      this.front_time = this.taskFanPage.front_time;
      this.to_time = this.taskFanPage.to_time;
      this.disabled = true;
    };
    if(![1,2].includes(this.currentUser.role_id)){
      this.roleDisabled = true;
    };
    this.getFanPages();
    this.getDataCatchers();
  },
  methods: {
    async getDataCatchers() {
        const res = await GlobalService.getOwners({
          modul: 15,
          body: {
            roles: "[9,10,11]",
            type: 1,
          },
        });
        this.catchers = res.data;
    },
    async getFanPages() {
      const data = await ActivitiesService.getListFanPages();
      this.fanpages = data;
    },
    closeModal() {
      this.$emit("close");
    },
    async assignFanpage() {
      try {
        const result = await this.$refs.form.validate();
        if (result) {
          this.spinnerOn = true;
          const params = {
            activitieId: this.activitieId,
            created_by: this.currentUser.user_id,
            catcherId: this.catcherId,
            front_time: this.front_time,
            to_time: this.to_time,
            front: this.front,
            to: this.to,
            fanpageId: this.fanpageId,
          };
          const data = await ActivitiesService.assignFanpage(params);
          if (data.status === 200) {
            this.showSuccessSwal();
            this.closeModal();
          }
        }
      } catch (e) {
        this.showErrorSwal(e);
        return [];
      }
    },
  },
};
</script>
  
  <style>
</style>
  