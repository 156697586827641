<template>
  <div>
    <b-container fluid class="pt-2">
      <b-row class="mt-1 mb-2" v-if="[1,2].includes(currentUser.role_id)">
        <b-col
          cols="12"
          lg="3"
          class="d-flex align-items-center justify-content-center"
        >
          <b-form-select v-model="catcherId" @input="emitEventDateChange">
            <b-form-select-option value="0"> All </b-form-select-option>
            <b-form-select-option
              v-for="(option, index) in catchers"
              :key="index"
              :value="option.id"
            >
              {{ option.user_name }}
            </b-form-select-option>
          </b-form-select>
        </b-col>
      </b-row>
      <b-row>
        <l-calendar
          ref="calendar"
          :events="events"
          date-location="due_date"
          date-location-format="YYYY-MM-DD"
          :list-title-background-color="skin !== 'dark' ? '#f4f4f4' : ''"
          @dateChange="fetchEvents"
          @openModalCreateFanpage="openModalCreateFanpage"
          @openModalAsignFanpage="openModalAsignFanpage"
          @openModalEditFanpage="openModalEditFanpage"
          :weekly="1"
        >
          <template #date-header="{ date, fullDay, haveEvents, currentDate }">
            <div
              class="py-50 text-center w-100 border-bottom font-weight-bolder"
              :class="
                currentDate
                  ? `bg-success text-white border-bottom-0 ${
                      skin !== 'dark' ? 'border-c4c4c4' : ''
                    }`
                  : haveEvents
                  ? `bg-primary text-white border-bottom-0 ${
                      skin !== 'dark' ? 'border-c4c4c4' : ''
                    }`
                  : `${skin !== 'dark' ? 'border-c4c4c4' : ''}`
              "
            >
              {{ fullDay }}, {{ date }}
            </div>
          </template>
          <template #date="{ events, haveEvents }">
            <div class="w-100" :class="haveEvents ? `text-white` : ''">
              <task-calendar
                v-if="haveEvents"
                :task="events"
                :type="'task'"
                :weekly="1"
                @emitEventDateChange="emitEventDateChange"
                @openMOdalEditAsignFanpage="openMOdalEditAsignFanpage"
              />
            </div>
          </template>
        </l-calendar>
      </b-row>
    </b-container>

    <create-fan-page-modal
      v-if="modalCreateFanPage"
      :editTask="editTask"
      :fanPageData="fanPage"
      @close="closeModalCreateFanpage"
    />

    <asign-fan-page-modal
      v-if="modalAsignFanPage"
      :editTask="editTask"
      :taskFanPage="taskFanPage"
      @close="closeModalAsignFanpage"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import LCalendar from "@/views/commons/calendar/LCalendar.vue";
import TaskCalendar from "@/views/crm/views/calendar/components/TaskCalendar.vue";
import ActivitiesService from "@/views/social-network/views/activities/activities.service";
import CreateFanPageModal from "./modals/CreateFanPageModal.vue";
import AsignFanPageModal from "./modals/AsignFanPage.vue";
import GlobalService from "../../../../../service/global";

export default {
  components: {
    LCalendar,
    CreateFanPageModal,
    AsignFanPageModal,
    TaskCalendar,
  },
  data() {
    return {
      events: [],
      modalCreateFanPage: false,
      editTask: false,
      modalAsignFanPage: false,
      modalEditFanPage: false,
      fanPage: [],
      catcherId: 0,
      catchers: [],
      taskFanPage: null,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
      token: "auth/token",
      skin: "appConfig/skin",
    }),
  },

  created() {
    this.getDataCatchers();
  },
  methods: {
    async getDataCatchers() {
      const res = await GlobalService.getOwners({
        modul: 15,
        body: {
          roles: "[9,10,11]",
          type: 1,
        },
      });
      this.catchers = res.data;
    },
    async fetchEvents({ month, year }) {
      try {
        this.addPreloader();
        const monthInfo = this.$moment(`${month}/1/${year}`);
        if (this.isModal) this.host = this.authUser.user_id;
        const response = await ActivitiesService.filterMonthNextTask({
          nyear: monthInfo.format("YYYY"),
          nmonth: monthInfo.format("MM"),
          id_user: (this.isCeo || this.isSupervisor) ? this.catcherId : this.currentUser.user_id,
        });
        if (this.isResponseSuccess(response)) {
          this.events = JSON.parse(response.data[0].events_month);
          this.removePreloader();
        } else {
          this.showToast(
            "warning",
            "top-right",
            "Warning!",
            "AlertTriangleIcon",
            `Something went wrong. ${response.message}`
          );
        }
      } catch (error) {
        this.showErrorSwal(error);
        this.removePreloader();
      }
    },
    emitEventDateChange() {
      this.$refs.calendar.emitEventDateChange();
    },
    openModalCreateFanpage() {
      this.modalCreateFanPage = true;
      this.fanPage = [];
      this.editTask = false;
    },
    closeModalCreateFanpage() {
      this.emitEventDateChange();
      this.modalCreateFanPage = false;
    },
    openModalAsignFanpage() {
      this.editTask = false;
      this.taskFanPage = null;
      this.modalAsignFanPage = true;
    },
    closeModalAsignFanpage() {
      this.emitEventDateChange();
      this.modalAsignFanPage = false;
    },
    openModalEditFanpage(fanPage) {
      this.fanPage = fanPage;
      this.editTask = true;
      this.modalCreateFanPage = true;
    },
    openMOdalEditAsignFanpage(data){
      this.taskFanPage = data;
      this.editTask = true;
      this.modalAsignFanPage = true;
    }
  },
};
</script>

<style scoped>
/*  */
</style>