<template>
  <b-modal
    id="modalTask"
    v-model="ownControl"
    modal
    scrollable
    title-class="h3 text-white font-weight-bolder"
    header-class="class_modal_js"
    size="lg"
    @hidden="closeModal"
  >
    <template #modal-header>
      <div class="d-flex justify-content-between w-100">
        <div class="text-center">
          <span style="font-size: 20px; font-weight: 900; color: #ffffff">{{
            editTask === false ? "CREATE FANPAGE" : (roleDisabled ? "FANPAGE" : "UPDATE FANPAGE")
          }}</span>
        </div>
        <feather-icon
          icon="XIcon"
          class="cursor-pointer"
          size="25"
          @click="closeModal"
        />
      </div>
    </template>

    <validation-observer ref="form">
      <b-form-group>
        <validation-provider
          v-slot="{ errors, valid }"
          name="fanpage"
          rules="required"
        >
          <b-input-group>
            <b-input-group-prepend>
              <b-input-group-text
                class="bg-primary text-white"
                style="padding-right: 5.25em"
              >
                <span>FANPAGE</span>
              </b-input-group-text>
            </b-input-group-prepend>
            <div class="d-flex align-items-center justify-content-around w-100">
              <b-form-select
                v-model="task.fanpage"
                :options="fanpage"
                text-field="value"
                value-field="id"
                :disabled="disabled"
                :state="errors[0] ? false : valid ? true : null"
              />
            </div>
          </b-input-group>
          <span v-if="errors[0]" class="text-danger">
            FANPAGE {{ errors[0] }}
          </span>
        </validation-provider>
      </b-form-group>

      <b-form-group>
        <validation-provider
          v-slot="{ errors, valid }"
          name="description"
          rules="required"
        >
          <b-input-group>
            <b-input-group-prepend>
              <b-input-group-text class="bg-primary text-white pr-2">
                <span>DESCRIPTION</span>
              </b-input-group-text>
            </b-input-group-prepend>
            <div class="w-100">
              <b-form-textarea
                v-model="task.description"
                placeholder="Enter description"
                class="input-form"
                :disabled="roleDisabled"
                :state="errors[0] ? false : valid ? true : null"
              />
            </div>
          </b-input-group>
          <span v-if="errors[0]" class="text-danger">
            Description {{ errors[0] }}
          </span>
        </validation-provider>
      </b-form-group>

      <b-form-group>
        <b-input-group>
          <b-col md="3" class="p-0">
            <b-input-group-prepend>
              <b-input-group-text class="bg-primary text-white w-100">
                <span>COLOR</span>
              </b-input-group-text>
            </b-input-group-prepend>
          </b-col>
          <b-col md="9" class="p-0">
            <div class="w-100">
              <b-input v-model="task.color" type="color" :disabled="roleDisabled"/>
            </div>
          </b-col>
        </b-input-group>
      </b-form-group>
    </validation-observer>

    <template #modal-footer>
      <div>
        <b-button v-if="editTask === false && !roleDisabled" variant="primary" @click="createFanpage()">
          <b-spinner v-if="spinnerOn" small />
          <feather-icon
            icon="SaveIcon"
            size="15"
            v-else
          />
          SAVE
        </b-button>
  
        <b-button v-if="editTask === true && !roleDisabled" variant="primary" @click="createFanpage()">
          <b-spinner v-if="spinnerOn" small />
          UPDATE
        </b-button>
      </div>
    </template>
  </b-modal>
</template>
<script>
import { mapGetters } from "vuex";

import AnswersGuideService from "@/views/social-network/views/answers-guide/answers-guide.service";

import ActivitiesService from "@/views/social-network/views/activities/activities.service";

export default {
  props: ["editTask","fanPageData"],
  data() {
    return {
      ownControl: false,
      spinnerOn: false,
      task: {
        id: null,
        fanpage: null,
        description: null,
        color: "#ffffff",
      },
      delete: null,
      fanpage: [],
      disabled: false,
      roleDisabled: false,
    };
  },

  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
      token: "auth/token",
    }),
  },

  created() {
    this.ownControl = true;
    this.getFanPages();
    if(this.editTask === true){
      this.task.id = this.fanPageData.id;
      this.task.fanpage = this.fanPageData.fanpage_id;
      this.task.description = this.fanPageData.description;
      this.task.color = this.fanPageData.color;
      this.disabled = true;
    };
    if(![1,2].includes(this.currentUser.role_id)){
      this.roleDisabled = true;
    };
  },
  methods: {
    async getFanPages() {
      let data = null;
      if(this.editTask === true){
        data = await AnswersGuideService.getFanPages();
      }else{
        data = await ActivitiesService.getFanPages();
      }
      this.fanpage = data;
    },
    closeModal() {
      this.$emit("close");
    },
    async createFanpage() {
      try {
        const result = await this.$refs.form.validate();
        if (result) {
          this.spinnerOn = true;
          const params = {
            taskId: this.task.id,
            fanpage: this.task.fanpage,
            description: this.task.description,
            color: this.task.color,
            created_by: this.currentUser.user_id,
          };
          const data = await ActivitiesService.createFanpage(params);
          if (data.status === 200) {
            this.showSuccessSwal();
            this.closeModal();
          }
        }
      } catch (e) {
        this.showErrorSwal(e);
        return [];
      }
    },
  },
};
</script>

<style>
</style>
